.xWIFB {

    -moz-column-gap: 1rem;

         column-gap: 1rem
}

@media (min-width: 0em) {

    .xWIFB {

        -moz-columns: 1;

             columns: 1
    }
}

@media (min-width: 48em) {

    .xWIFB {

        -moz-columns: 2;

             columns: 2
    }
}

@media (min-width: 80em) {

    .xWIFB {

        -moz-columns: 6;

             columns: 6
    }
}

    .xWIFB .neeMz {

    display: inline-block;

    width: 100%
}
