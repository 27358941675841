:root {
  /*
    * These below colours would be deprecated after design implementation
    * As these colours are used in current core, site and other repositories
  */

  /**
    * Below colours are from new style guidelines
  */
  /* Primary colors RED Orange */ /* Red */ /* Red */ /* Dark Red*/

  /* Black scale */ /* Black */ /* Thick Black */ /* light black */

  /* White scale */ /* white */ /* Light White */  /* One kind of white */

  /* Gray scale */ /* Gray */ /* Version of Light Gray */ /* Light Gray */ /* Davy Gray */ /* Solid Gray */ /* Solid Light Gray */ /* Dark Gray */ /* Dark Solid Gray */

  /* Inherited from Design Token to remove */

  /* Other Colors */ /* Turquoise */
  
  /* Extended */

  /* Messaging colors */ /* Anchor Blue */

  /* Message Alert colors */

  
  /* a.n.a Rebrand Colors */
  /* a.n.a Rebrand Colors Ends */

}

.IhLt6 {
    transition: all .1s ease-out;
    visibility: visible;
    position: absolute;
    z-index: 12;
    display: block;
    height: 0px;
    width: 90%;
}

.IhLt6 .wYFri {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  --tw-text-opacity: 1;
  color: rgba(32, 32, 32, var(--tw-text-opacity));

}

.IhLt6 .wYFri .hg-n- {
  height: 1.5rem;
  width: 1.5rem;

}

.IhLt6 .Qm-jq {
        box-shadow: 0 0.25rem 0.1875rem 0.1875rem rgba(0,0,0,.1);
        visibility: hidden;
        height: 500px;
        overflow: auto;
        --tw-bg-opacity: 1;
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgba(32, 32, 32, var(--tw-text-opacity));
    }

.IhLt6 .lubtx {
  visibility: visible;

}

.IhLt6 .-yHaZ {
  visibility: hidden;

}

.IhLt6 .Zblii {
  font-size: 1rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(32, 32, 32, var(--tw-text-opacity));

}

.IhLt6 .hCsWL {
  font-size: 0.875rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(32, 32, 32, var(--tw-text-opacity));

}

.IhLt6 ._7N09h {
  font-size: 0.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(32, 32, 32, var(--tw-text-opacity));

}

.IhLt6 .m5k-Z {
  position: absolute;
  top: 0px;
  left: 0px;
  --tw-text-opacity: 1;
  color: rgba(32, 32, 32, var(--tw-text-opacity));

}

.headerBot [data-automation-id="logoBlock"] {
  position: relative;
  left: 1.5rem;
  top: -0.125rem;

}

.headerBot [data-automation-id="headerMainBlock"] [data-automation-id="logoBlock"] [data-automation-id="mobSearchIcon"] {
  top: 2.5rem;
  right: -0.5rem;

}
