.FamI- .OwNke {
        word-break: break-word;
    }
    .FamI- .vQGxO {
        display: flex !important;
}
    .FamI- .vQGxO {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
        font-weight: 400;
        flex-direction: column;
        row-gap: 0.25rem;
        --tw-text-opacity: 1;
        color: rgba(77, 77, 77, var(--tw-text-opacity));
}
    .FamI- ._32dCX {
        font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        --tw-text-opacity: 1;
        color: rgba(77, 77, 77, var(--tw-text-opacity));
}
    @media (min-width: 0em) {
        .FamI- .DVWef {
                font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
                font-weight: 400;
        }
}
