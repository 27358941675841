/* media queries for different width */
/* Medium plus screens */
/* Large plus screens */
/***
    Added only to Make the Header and Footer as Per the classice
    Have to remove once the Yoda Header Footer developed
***/
/***
    Adding  below for Header ReDesign
***/
@media (min-width: 600px) and (max-width: 1024px), (min-width: 1025px) {
            .tHa9u.QORFo [size="7"], .tHa9u._8--6j [size="7"] {
                font-size: 80px;
                line-height: 86px;
            }
            .tHa9u.QORFo [size="6"], .tHa9u._8--6j [size="6"] {
                font-size: 64px;
                line-height: 72px;
            }
            .tHa9u.QORFo [size="11"], .tHa9u._8--6j [size="11"] {
                font-size: 52px;
                line-height: 64px;
            }
            .tHa9u.QORFo [size="5"], .tHa9u._8--6j [size="5"] {
                font-size: 40px;
                line-height: 48px;
            }
            .tHa9u.QORFo [size="4"], .tHa9u._8--6j [size="4"] {
                font-size: 32px;
                line-height: 40px;
            }
            .tHa9u.QORFo [size="10"],
            .tHa9u.QORFo [size="9"],
            .tHa9u.QORFo [size="3"],
            .tHa9u._8--6j [size="10"],
            .tHa9u._8--6j [size="9"],
            .tHa9u._8--6j [size="3"] {
                font-size: 24px;
                line-height: 32px;
            }
            .tHa9u.QORFo [size="8"],
            .tHa9u.QORFo [size="2"],
            .tHa9u._8--6j [size="8"],
            .tHa9u._8--6j [size="2"] {
                font-size: 16px;
                line-height: 20px;
            }
        }
@media (max-width: 599px) {
            .tHa9u.QORFo [size="7"], .tHa9u._8--6j [size="7"] {
                font-size: 36px;
                line-height: 44px;
            }
            .tHa9u.QORFo [size="11"],
            .tHa9u.QORFo [size="6"],
            .tHa9u._8--6j [size="11"],
            .tHa9u._8--6j [size="6"] {
                font-size: 32px;
                line-height: 40px;
            }
            .tHa9u.QORFo [size="5"], .tHa9u._8--6j [size="5"] {
                font-size: 28px;
                line-height: 36px;
            }
            .tHa9u.QORFo [size="10"],
            .tHa9u.QORFo [size="4"],
            .tHa9u._8--6j [size="10"],
            .tHa9u._8--6j [size="4"] {
                font-size: 24px;
                line-height: 32px;
            }
            .tHa9u.QORFo [size="9"],
            .tHa9u.QORFo [size="3"],
            .tHa9u._8--6j [size="9"],
            .tHa9u._8--6j [size="3"] {
                font-size: 20px;
                line-height: 28px;
            }
            .tHa9u.QORFo [size="8"],
            .tHa9u.QORFo [size="2"],
            .tHa9u._8--6j [size="8"],
            .tHa9u._8--6j [size="2"] {
                font-size: 16px;
                line-height: 20px;
            }
        }
@media (min-width: 600px) and (max-width: 1024px), (min-width: 1025px) {
            .tHa9u._9Mng9 [size="9"], .tHa9u ._8--6j [size="9"] {
                font-size: 24px;
                line-height: 32px;
            }
            .tHa9u._9Mng9 [size="8"],
            .tHa9u._9Mng9 [size="4"],
            .tHa9u ._8--6j [size="8"],
            .tHa9u ._8--6j [size="4"] {
                font-size: 16px;
                line-height: 20px;
            }
            .tHa9u._9Mng9 [size="3"], .tHa9u ._8--6j [size="3"] {
                font-size: 14px;
                line-height: 20px;
            }
        }
@media (max-width: 599px) {
            .tHa9u._9Mng9 [size="9"], .tHa9u ._8--6j [size="9"] {
                font-size: 20px;
                line-height: 28px;
            }
            .tHa9u._9Mng9 [size="8"], .tHa9u ._8--6j [size="8"] {
                font-size: 16px;
                line-height: 20px;
            }
            .tHa9u._9Mng9 [size="4"],
            .tHa9u._9Mng9 [size="3"],
            .tHa9u ._8--6j [size="4"],
            .tHa9u ._8--6j [size="3"] {
                font-size: 14px;
                line-height: 20px;
            }
        }
.tHa9u.HK5tv [size="1"] {
            font-size: 12px;
            line-height: 16px;
        }