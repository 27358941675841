.OYqm2 {
    width: 85%;
}

.tYBrl {
    transform: rotate(-90deg);
}

.YqtdX {
    letter-spacing: -0.12px;
}

/*
 * react-circular-progressbar default styles
 */

.CircularProgressbar {

        /*
         * This fixes an issue where the CircularProgressbar svg has
         * 0 width inside a "display: flex" container, and thus not visible.
         */
        width: 100%;
        /*
         * This fixes a centering issue with CircularProgressbarWithChildren:
         * https://github.com/kevinsqi/react-circular-progressbar/issues/94
         */
        vertical-align: middle;
    }

.CircularProgressbar .CircularProgressbar-path {
        stroke: #3e98c7;
        stroke-linecap: round;
        transition: stroke-dashoffset 0.5s ease 0s;
    }

.CircularProgressbar .CircularProgressbar-trail {
        stroke: #d6d6d6;
        /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
        stroke-linecap: round;
    }

.CircularProgressbar .CircularProgressbar-text {
        fill: #3e98c7;
        font-size: 20px;
        dominant-baseline: middle;
        text-anchor: middle;
    }

.CircularProgressbar .CircularProgressbar-background {
        fill: #d6d6d6;
    }

/*
     * Sample background styles. Use these with e.g.:
     *
     *   <CircularProgressbar
     *     className="CircularProgressbar-inverted"
     *     background
     *     percentage={50}
     *   />
     */

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
        fill: #3e98c7;
    }

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
        fill: #fff;
    }

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
        stroke: #fff;
    }

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
        stroke: transparent;
    }


