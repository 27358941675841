@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url("https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3bO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzKRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url("https://fonts.gstatic.com/s/opensans/v17/mem6YaGs126MiZpBA-UFUK0Zdc1GAK6b.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBrrIa-7acMAeDBVuclsi6Gc.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local("Open Sans Semibold Italic"), local("OpenSans-SemiBoldItalic"),
        url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKXGUdhrIqOxjaPX.woff2") format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
		 url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKWiUNhrIqOxjaPX.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root { /* 16px */ /* 20px */ /* 28px */ /* 32px */ /* 12px */ /* 14px */ /* 16px */ /* 20px */ /* 24px */ /* 0px */ /* 8px */ /* 16px */ /* 24px */ /* 32px */ /* 40px */ /* 48px */ /* 56px */ /* 64px */ /* 72px */ /* 80px */ /* 88px */ /* 96px */ /* 104px */ /* 112px */ /* 120px */ /* 4px */
  /*
    * These below colours would be deprecated after design implementation
    * As these colours are used in current core, site and other repositories
  */

  /**
    * Below colours are from new style guidelines
  */
  /* Primary colors RED Orange */ /* Red */ /* Red */ /* Dark Red*/

  /* Black scale */ /* Black */ /* Thick Black */ /* light black */

  /* White scale */ /* white */ /* Light White */  /* One kind of white */

  /* Gray scale */ /* Gray */ /* Version of Light Gray */ /* Light Gray */ /* Davy Gray */ /* Solid Gray */ /* Solid Light Gray */ /* Dark Gray */ /* Dark Solid Gray */

  /* Inherited from Design Token to remove */

  /* Other Colors */ /* Turquoise */
  
  /* Extended */

  /* Messaging colors */ /* Anchor Blue */

  /* Message Alert colors */

  
  /* a.n.a Rebrand Colors */
  /* a.n.a Rebrand Colors Ends */
}

/* media queries for different width */

/* Medium plus screens */

/* Large plus screens */

/***
    Added only to Make the Header and Footer as Per the classice
    Have to remove once the Yoda Header Footer developed
***/

/***
    Adding  below for Header ReDesign
***/

.NV-f-.jVahW ._86iL9 {
    height: 100%;
    border-width: 0px;
            min-width: auto;
}

.NV-f-.jVahW ._86iL9 ._4cOaw {
    display: none;
}

@media (min-width: 48em) and (max-width: 79.9375em) {

    .NV-f-.jVahW ._86iL9 .TrBn7 {
        min-height: 104px;
    }
}

@media (min-width: 80em) {

    .NV-f-.jVahW ._86iL9 .TrBn7 {
        min-height: 100px;
    }
}

.NV-f-.jVahW ._86iL9 .TrBn7 img {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
}

.NV-f-.jVahW ._86iL9 .TrBn7 ._2edN- {
    overflow: hidden;
}

@media (min-width: 0em) {

    .NV-f-.jVahW ._86iL9 .TrBn7 ._2edN- {
        height: auto;
    }
}

@media (max-width: 47.999em) {

    .NV-f-.jVahW ._86iL9 .TrBn7.Yihqd {
        min-height: 80px;
    }
}

.NV-f-.jVahW ._86iL9 ._03Ej2 {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0px;
    height: auto;
    width: auto;
    --tw-translate-x: -50%;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    padding: 0px;
}

.NV-f-.o9SxB.jVahW .TrBn7 ._2edN- {
    height: 104px;
    overflow: hidden;
}

._9nrbr {

    background: none;
    margin-top: 8px;
    /* inline-block fix */
    font-size: 0;
}

.z9khh {
}

.w-nN- {
    text-align: center;
    min-height: 24px;
    width: 100%;
    background: #1C2024;
    color: #FFFFFF;
    font: normal 14px/24px 'Open Sans', OpenSans, sans-serif;
    padding: 0px 20px;
}

@media(min-width: 1025px) {

.w-nN- {
        font-size: 16px;
        padding-top: 1px
}
    }

.w-nN- .SBLwF {
        position: absolute;
        bottom: 4px;
        width: 100%;
        background-color: transparent;
        height: 10px;
        vertical-align: top;
    }

.w-nN- .SBLwF li {
            height: 10px;
        }

.w-nN- .SBLwF li:first-child {
                width: 8px;
            }

.w-nN- .SBLwF li:first-child .z9khh {
                    margin: 0;
                }

.w-nN- .z9khh {
        vertical-align: top;
        margin: 0 0 0 8px;
        border: 0;
    }

.JztKS {
    padding: 0;
}

._8M7zU {
    animation-name: RHyZQ;
    opacity: 0;
}

.A4wm6 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    max-width: 100%;
}

@media(min-width: 1025px) {

.A4wm6 {
        min-height: 29px
}
    }

.A4wm6 .Yzyz- a {
            color: #FFFFFF;
            text-decoration: underline;
            cursor: pointer;
            font-weight: bold;
        }

.A4wm6 .YCe-Z {
        color: #FFFFFF;
        text-decoration: underline;
        cursor: pointer;
        font-weight: bold;
        line-height: 24px;
        margin-left: 5px;
        white-space: nowrap;
    }

._3Jr0r {
    white-space: normal;
    padding: 0px;
    min-height: 32px;
}

@media(max-width: 599px) {

._3Jr0r {
        width: 304px
}
    }

@media(min-width: 600px) and (max-width: 1024px) {

._3Jr0r {
        width: 495px
}
    }

@media(min-width: 1025px) {

._3Jr0r {
        width: 920px
}
    }

._3Jr0r .YCe-Z {
        color: #FFFFFF;
        cursor: pointer;
        font-weight: normal;
        line-height: 16px;
        margin-left: 0px;
        font-size: 14px;
        white-space: normal;
        text-decoration: none;
        padding: 0;
    }

._3Jr0r .hvTbw{
        cursor: default;
    }

._3Jr0r .yP-Ox {
        text-decoration: underline;
        padding-left: 10px;
        display: inline-block;
    }

.BJQ45.jlOdU {
    background: transparent;
}

.BJQ45 {
    min-height: 24px;
    width: 100%;
    background: #1C2024;
    color: #FFFFFF;
    text-align: center;
    font: normal 14px/24px 'Open Sans', OpenSans, sans-serif;
    padding: 0px 20px 0px 20px;
}

@media(min-width: 1025px) {

.BJQ45 {
        min-height: 29px
}
    }

.toL6T.w-nN- {
        text-align: center;
        padding: 0px;
        width: 100%;
        font: normal 16px/24px 'Open Sans', OpenSans, sans-serif;
        background: #e8e5de;
        color: #202020;
        min-height: 24px;
    }

.toL6T.w-nN- .UKI3c.vlKQ8{
                width: 20%;
                background: black;
            }

.toL6T ._8M7zU {
        animation-name: RHyZQ;
        opacity: 0;
    }

.toL6T .A4wm6 {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        max-width: 100%;
        padding-bottom: 16px;
    }

.toL6T .A4wm6.FOqcf {
            padding-bottom: 0;
        }

@media(min-width: 1025px) {

.toL6T .A4wm6 {
            min-height: 40px
    }
        }

.toL6T .A4wm6 .Yzyz- a {
                color: #202020;
                text-decoration: underline;
                cursor: pointer;
                font-weight: 600;
            }

.toL6T .A4wm6 .YCe-Z {
            color: #202020;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 600;
            line-height: 24px;
            margin-left: 5px;
            white-space: nowrap;
            vertical-align: center;
            display: inline-block;
        }

.toL6T ._3Jr0r {
        white-space: normal;
        height: 72px;
    }

@media(max-width: 599px) {

.toL6T ._3Jr0r {
            width: 100%
    }
        }

@media(min-width: 600px) and (max-width: 1024px) {

.toL6T ._3Jr0r {
            width: 100%
    }
        }

@media(min-width: 1025px) {

.toL6T ._3Jr0r {
            width: 100%
    }
        }

.toL6T ._3Jr0r .YCe-Z {
            color: #202020;
            cursor: pointer;
            font-weight: normal;
            line-height: 20px;
            margin-left: 0px;
            font-size: 12px;
            white-space: normal;
            text-decoration: none;
            padding: 0;
        }

.toL6T ._3Jr0r .hvTbw{
            cursor: default;
        }

.toL6T ._3Jr0r .yP-Ox {
            text-decoration: underline;
            display: inline-block;
            font-size: 14px;
        }

.toL6T .BJQ45 {
        min-height: 40px;
        width: 100%;
        background: #e8e5de;
        color: #202020;
        text-align: center;
        font: normal 14px/24px 'Open Sans', OpenSans, sans-serif;
        padding: 0px 20px 0px 20px;
    }

@media(min-width: 1025px) {

.toL6T .BJQ45 {
            min-height: 40px
    }
        }

.slick-slider ul li.slick-active button {
                    opacity: 1;
                    background: #727272;
                }

@keyframes RHyZQ {
    0% { opacity: 0; }
    25% { opacity: 1; }
    75% { opacity: 1; }
    100% { opacity: 0; }
}
