@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url("https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3bO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzKRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url("https://fonts.gstatic.com/s/opensans/v17/mem6YaGs126MiZpBA-UFUK0Zdc1GAK6b.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBrrIa-7acMAeDBVuclsi6Gc.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local("Open Sans Semibold Italic"), local("OpenSans-SemiBoldItalic"),
        url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKXGUdhrIqOxjaPX.woff2") format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
		 url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKWiUNhrIqOxjaPX.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root { /* 16px */ /* 20px */ /* 28px */ /* 32px */ /* 12px */ /* 14px */ /* 16px */ /* 20px */ /* 24px */ /* 0px */ /* 8px */ /* 16px */ /* 24px */ /* 32px */ /* 40px */ /* 48px */ /* 56px */ /* 64px */ /* 72px */ /* 80px */ /* 88px */ /* 96px */ /* 104px */ /* 112px */ /* 120px */ /* 4px */
  /*
    * These below colours would be deprecated after design implementation
    * As these colours are used in current core, site and other repositories
  */

  /**
    * Below colours are from new style guidelines
  */
  /* Primary colors RED Orange */ /* Red */ /* Red */ /* Dark Red*/

  /* Black scale */ /* Black */ /* Thick Black */ /* light black */

  /* White scale */ /* white */ /* Light White */  /* One kind of white */

  /* Gray scale */ /* Gray */ /* Version of Light Gray */ /* Light Gray */ /* Davy Gray */ /* Solid Gray */ /* Solid Light Gray */ /* Dark Gray */ /* Dark Solid Gray */

  /* Inherited from Design Token to remove */

  /* Other Colors */ /* Turquoise */
  
  /* Extended */

  /* Messaging colors */ /* Anchor Blue */

  /* Message Alert colors */

  
  /* a.n.a Rebrand Colors */
  /* a.n.a Rebrand Colors Ends */
}

/* media queries for different width */

/* Medium plus screens */

/* Large plus screens */

/***
    Added only to Make the Header and Footer as Per the classice
    Have to remove once the Yoda Header Footer developed
***/

/***
    Adding  below for Header ReDesign
***/

.lpUbn.oNu9F {
        margin: 0 auto;
        display: table;
        width: 100%;  
    }

.lpUbn.oNu9F .t--Kq {
            display: table-cell;
            width: 90%;
        }

.lpUbn.oNu9F .t--Kq .dQGFI {
                color: #202020;
                width: 100%;
                font: bold 16px/24px 'Open Sans', OpenSans, sans-serif;
                text-align: left;
                padding: 12px 16px 4px;
                display: table;
                border-top: 0;
            }

@media (min-width: 1025px) {

.lpUbn.oNu9F .t--Kq .dQGFI {
                    padding: 12px 24px 4px
            }
                }

.lpUbn.oNu9F .t--Kq .dQGFI .OirUA {
                    font: bold 16px/24px 'Open Sans', OpenSans, sans-serif;
                }

.lpUbn.oNu9F .t--Kq .dQGFI .v2VoA {
                    display: table-cell;
                    width: 100%;
                }

.lpUbn.oNu9F .t--Kq .dQGFI ._5z7Xx {
                    display: table-cell;
                    text-align: right;
                }

.lpUbn.oNu9F .t--Kq .dQGFI ._5z7Xx ._2qmnQ {
                        color: #202020;
                    }

.jf3w9{
    display: flex;
    width: 100%;
    margin-bottom: 8px;
}

.mmdxI .WSA6E{
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
    }

.mmdxI .WSA6E .MF3Mr{
            display: flex;
            display: -webkit-flex;
        }

.mmdxI .WSA6E .MF3Mr .OunE9{
                display: flex!important;
            }

.mmdxI .WSA6E .MF3Mr .OunE9{
                display: -webkit-flex;
                margin-bottom: 8px;
            }
