/* .slick-slider
    {
        @apply relative block box-border;
    } */
    .slick-list {
overflow: hidden; 
}
    /* .slick-slide
    {
        @apply hidden float-left h-full min-h-[1px];
    } */
    .slick-initialized .slick-slide {
display: block; 
}
    .slick-vertical .slick-slide {
display: block;
height: auto;
border-width: 1px;
border-style: solid;
border-color: transparent; 
}

@media (min-width: 80em) {
._1KrxM._9JqNT {
width: 100%; 
} 
}

._1KrxM .iKb-Q, ._1KrxM .ox-Gw {
display: block;
cursor: pointer;
font-size: 1.5rem;
line-height: 1;
--tw-text-opacity: 1;
color: rgba(255, 255, 255, var(--tw-text-opacity));
text-decoration-line: none; 
}

._9Jq4- {
--tw-bg-opacity: 1;
background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
text-align: center; 
}

._9Jq4- > li {
display: inline-block; 
}

@media (max-width: 47.999em) {
._9Jq4- > li {
height: 1.25rem;
width: 1.25rem; 
} 
}

._9Jq4- .slick-active button {
--tw-bg-opacity: 1;
background-color: rgba(231, 19, 36, var(--tw-bg-opacity)); 
}

._9Jq4- .slick-active .iconButton {
background-color: transparent; 
}

._9Jq4- .slick-active .iconButton .svg-icon {
fill: #666;
opacity: 1; 
}

._9Jq4- .svg-icon {
margin: 5px; 
}

@media (max-width: 47.999em) {
._9Jq4- .svg-icon {
margin-top: 0.375rem; 
} 
}

@media (min-width: 48em) {
._9Jq4- .svg-icon {
--tw-translate-x: 0px;
--tw-translate-y: -10%;
transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); 
} 
}

@media (min-width: 48em) and (max-width: 79.9375em) {
._9Jq4- .svg-icon {
margin-top: 0.5rem; 
} 
}

@media (max-width: 79.9375em) {
._9Jq4- .svg-icon {
height: 0.625rem;
width: 0.625rem; 
} 
}

.LI54t {
overflow: hidden; 
}

.n7h2e {
margin: 5px;
display: inline-block;
height: 0.625rem;
width: 0.625rem;
cursor: pointer;
border-radius: 50%;
border-width: 1px;
border-style: solid;
--tw-border-opacity: 1;
border-color: rgba(255, 255, 255, var(--tw-border-opacity));
--tw-bg-opacity: 1;
background-color: rgba(216, 216, 216, var(--tw-bg-opacity)); 
}

.TSWp1 {
transition-property: width;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 200ms;
transition-timing-function: cubic-bezier(0.4, 0, 1, 1); 
}

@media (max-width: 47.999em) {
.TSWp1 {
margin-right: 0.25rem;
width: 14rem; 
} 
}

@media (min-width: 48em) {
.TSWp1 {
width: 48vw; 
} 
}

@media (min-width: 80em) {
.TSWp1 {
width: 48vw; 
} 
}

@media (max-width: 47.999em) {

.TSWp1 {
        will-change: width;
} 
    }

._3eGSE .TSWp1 {
margin: auto; 
}

/*******************************************************************************/

/************************* React Slick *****************************************/

/*******************************************************************************/

/* for server side rendering, it will get overriden by js */

@media (max-width: 47.999em) {
._1KrxM .slick-track {
width: 1000000px; 
} 
}

@media (min-width: 48em) {
._1KrxM .slick-track {
width: 1000000px; 
} 
}

._1KrxM li.slick-active, ._1KrxM .slick-active {
--tw-border-opacity: 1;
border-color: rgba(102, 102, 102, var(--tw-border-opacity));
opacity: 1; 
}

._1KrxM .slick-active .iconButton svg {
fill: #4d4d4d; 
}

._1KrxM .slick-list {
padding: 0px !important; 
}

@media (max-width: 47.999em) {
._1KrxM .slick-list {
margin-bottom: 0.5rem; 
} 
}

._1KrxM .slick-arrow {
position: absolute;
top: 50%;
z-index: 1;
--tw-translate-y: -50%;
transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
font-size: 0; 
}

._1KrxM .slick-arrow::before {
position: absolute;
top: 0px;
left: 0px;
display: inline-block;
height: 1rem;
width: 1rem;
--tw-rotate: -45deg;
transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
border-top-width: 2px;
border-right-width: 2px;
border-bottom-width: 0px;
border-left-width: 0px;
border-style: solid;
--tw-content: '';
content: var(--tw-content); 
}

@media (width: 1280px) {
                ._1KrxM .slick-arrow::before {
left: -1.5rem; 
} 
            }

._1KrxM .slick-disabled {
--tw-translate-x: -10000px;
transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); 
}

._1KrxM .slick-next {
right: 1.5rem; 
}

._1KrxM .slick-next::before {
--tw-rotate: 45deg;
transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); 
}

._1KrxM .slick-prev {
left: 0.625rem; 
}

._1KrxM .slick-prev::before {
--tw-rotate: -135deg;
transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); 
}

.GsnbP {
justify-content: flex-end;
overflow-y: hidden; 
}

@media (max-width: 79.9375em) {
.N076Z {
display: none; 
} 
}

.N076Z .Efon8 {
margin-top: 1.25rem;
margin-right: 1rem;
display: inline-flex;
white-space: normal;
        max-height: -webkit-fill-available; 
}

.N076Z .Efon8 .pgoKo .HwV8Q {
position: absolute;
top: 50%;
left: 55%;
box-sizing: border-box;
display: inline-block;
border-top-width: 6px;
border-right-width: 0px;
border-bottom-width: 6px;
border-left-width: 9px;
border-style: solid;
border-color: transparent;
--tw-border-opacity: 1;
border-left-color: rgba(102, 102, 102, var(--tw-border-opacity));
                transform: translate3d(-50%, -50%, 0); 
}

.N076Z .Efon8 .nORU2 {
font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
max-height: 2.25rem;
overflow: hidden;
font-size: 0.75rem;
font-weight: 400;
font-style: normal;
line-height: 1rem;
letter-spacing: 0em;
--tw-text-opacity: 1;
color: rgba(102, 102, 102, var(--tw-text-opacity));
            display: -webkit-inline-box;
            font-stretch: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; 
}