/* horizontal Carousel ====> START*/
img {
    vertical-align: middle;
}
/* Next & previous buttons */
.QGBsI, ._3aTl2 {
    position: absolute;
    top: 50%;
    height: 2rem;
    width: 2rem;
    --tw-translate-y: calc(calc(50% + 10px) * -1);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.92);
    padding: 0px;
    font-size: 1.125rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity));
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
/* Position the "next button" to the right */
._3aTl2 {
    border-radius: 3px 0 0 3px;
    right: 0px;
}
.QGBsI::before, ._3aTl2::before {
    position: absolute;
    top: 0.5rem;
    left: 0.25rem;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-style: solid;
    --tw-content: '';
    content: var(--tw-content);
}
.QGBsI::before {
    left: 0.625rem;
    --tw-rotate: -135deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.uU4Wx svg {
    fill: #e71324;
}
.ZaStM svg {
    fill: #666;
}
/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
    .QGBsI,
    ._3aTl2,
    .R2g-M {
        font-size: 11px;
    }
}
.Alzay ._4PjwT {
    min-height: 150px;
    --tw-bg-opacity: 1;
    background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}
/* horizontal Carousel ====> END*/
