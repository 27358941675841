/* media queries for different width */
/* Medium plus screens */
/* Large plus screens */
/***
    Added only to Make the Header and Footer as Per the classice
    Have to remove once the Yoda Header Footer developed
***/
/***
    Adding  below for Header ReDesign
***/
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans'), local('OpenSans'), url("https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3bO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url("https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzKRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url("https://fonts.gstatic.com/s/opensans/v17/mem6YaGs126MiZpBA-UFUK0Zdc1GAK6b.woff2") format('woff2'), url(https://fonts.gstatic.com/s/opensans/v13/xjAJXh38I15wypJXxuGMBrrIa-7acMAeDBVuclsi6Gc.woff) format('woff');
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local("Open Sans Semibold Italic"), local("OpenSans-SemiBoldItalic"),
        url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKXGUdhrIqOxjaPX.woff2") format("woff2");
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
		 url("https://fonts.gstatic.com/s/opensans/v17/memnYaGs126MiZpBA-UFUKWiUNhrIqOxjaPX.woff2") format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
:root { /* 16px */ /* 20px */ /* 28px */ /* 32px */ /* 12px */ /* 14px */ /* 16px */ /* 20px */ /* 24px */ /* 0px */ /* 8px */ /* 16px */ /* 24px */ /* 32px */ /* 40px */ /* 48px */ /* 56px */ /* 64px */ /* 72px */ /* 80px */ /* 88px */ /* 96px */ /* 104px */ /* 112px */ /* 120px */ /* 4px */
}
.FCCqP {
  display: block;
}
.JEVaA {
  top: 100%;
  left: 0%;
}
._0m3Z3 {
  bottom: 130%;
  right: -15%;
}
.ATx1u {
  bottom: 130%;
  left: 0%;
}
._537Wl {
  left: 105%;
  top: 50%;
  transform: translateY(-50%);
}
.Ctqqc {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.HSW-i {
  /*border: 1px solid;*/
}
.vd5F3.bkeSG::after,
.JEVaA.bkeSG::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 26px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
}
.vOnnR .vd5F3.bkeSG::after,
  .vOnnR .JEVaA.bkeSG::after {
    transform: none;
    border-color: transparent transparent #ffffff;
  }
.vOnnR .vd5F3 {
    top: 100%;
  }
.vd5F3._0iqKu::after,
.JEVaA._0iqKu::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 15px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}
.vd5F3.fvl90::after,
.JEVaA.fvl90::after {
  content: "";
  position: absolute;
  bottom: 100%;
  margin-left: -5px;
  left: 50%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}
/*& .Bottomleft.Left::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 15%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}*/
._0m3Z3._0iqKu::after,
.ATx1u._0iqKu::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 5%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
}
.ATx1u.bkeSG::after,
._0m3Z3.bkeSG::after {
  content: "";
  position: absolute;
  top: 100%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
}
.ATx1u.fvl90::after,
._0m3Z3.fvl90::after {
  content: "";
  position: absolute;
  margin-left: -5px;
  left: 50%;
  top: 100%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
}
._537Wl.fvl90::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
._537Wl.bkeSG::before {
  content: "";
  position: absolute;
  /*top: 50%;*/
  bottom: 10px;
  right: 100%;
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
._537Wl._0iqKu::before {
  content: "";
  position: absolute;
  /*top: 50%;*/
  top: 20px;
  right: 100%;
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.Ctqqc.fvl90::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  /* To the right of the tooltip */
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.Ctqqc.bkeSG::after {
  content: " ";
  position: absolute;
  bottom: 10px;
  left: 100%;
  /* To the right of the tooltip */
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.Ctqqc._0iqKu::after {
  content: " ";
  position: absolute;
  top: 20px;
  left: 100%;
  /* To the right of the tooltip */
  margin-top: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.vd5F3 {
  top: 100%;
  /*left: 100%;*/
  right: -25px;
}
/* Custom Tooltip */
.zSUHH::after, .zSUHH::before {
    display: none;
}
.zSUHH.ccQaX::after,
    .zSUHH.ccQaX::before {
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      bottom: -4px;
      content: ' ';
      display: block;
      height: 0;
      position: absolute;
      width: 0;
      z-index: 20;
    }
.zSUHH.ccQaX.JzRj8::after,
      .zSUHH.ccQaX.JzRj8::before {
        z-index: 50;
      }
.zSUHH.ccQaX::after {
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
@media (min-width: 0em) {
    .zSUHH.ccQaX::after {
        border-bottom-width: 10px;
    }
}
.zSUHH.ccQaX::after {
      bottom: -5px;
    }
.zSUHH.ccQaX::before {
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(216, 216, 216, var(--tw-border-opacity));
}
@media (min-width: 0em) {
    .zSUHH.ccQaX::before {
        border-bottom-width: 10px;
    }
}
.zSUHH.ccQaX.kGm92::after,
      .zSUHH.ccQaX.kGm92::before {
        bottom: initial;
        top: -4px;
        transform: rotate(180deg);
      }
.zSUHH.ccQaX.kGm92::after {
        top: -5px;
      }
.PmN5H {
  top: 0;
  left: 0;
}
.PmN5H.JzRj8 {
    opacity: 1;
  }
.X6nGi {
  top: calc(100% + 2px);
  width: 220px;
  z-index: 20;
}
.X6nGi.JzRj8 {
    z-index: 50;
  }
.X6nGi.kGm92 {
    bottom: calc(100% + 2px);
    top: initial;
  }