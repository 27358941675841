/* hybrid menu styles */
    .Rw7N6.stIjS svg use {
                fill: #202020;
}
    .Rw7N6.stIjS nav svg use {
                --tw-text-opacity: 1;
                color: rgba(216, 216, 216, var(--tw-text-opacity));
}
    .Rw7N6.stIjS nav + li {
                margin-top: 0.5rem;
                border-top-width: 1px;
                border-style: solid;
                --tw-border-opacity: 1;
                border-color: rgba(216, 216, 216, var(--tw-border-opacity));
                padding-top: 0.5rem;
}
    .Rw7N6.stIjS ._6tZDj li {
                position: relative;
                z-index: 2;
}
    .Rw7N6.stIjS ._6tZDj li svg use {
                fill: #202020;
}
    .Rw7N6.stIjS ._6tZDj .-k7Al {
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
}
    .Rw7N6.stIjS ._6tZDj .-k7Al span.bzSKe {
                font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
                font-weight: 600;
}
    .Rw7N6.stIjS .QZC-0 .-k7Al {
                width: -moz-fit-content;
                width: fit-content;
            }
    .Rw7N6.stIjS .QZC-0 ._7BJ2U {
                transform: translateY(-50%);
            }
    /* override classes defined by config */
    .Rw7N6.stIjS .JMcSH {
                --tw-text-opacity: 1;
                color: rgba(231, 19, 36, var(--tw-text-opacity));
}
    .Rw7N6.stIjS .Wf9G1 {
                --tw-text-opacity: 1;
                color: rgba(32, 32, 32, var(--tw-text-opacity));
}
    .Rw7N6 .lLxFY {
        font-size: 14px!important;
    }
    .Rw7N6 ._4O42w {
        font-size: 12px!important;
    }
