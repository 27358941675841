.zuODa {
    position: relative;
    z-index: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1440px
}

@media (max-width: 47.999em) {

    .zuODa {
        min-height: 72px
    }
}

@media (min-width: 48em) {

    .zuODa {
        min-height: 37px
    }
}

@media (max-width: 79.9375em) {

    .zuODa {
        z-index: auto
    }
}

    .zuODa.IHE8l {
    z-index: 4;
    margin-top: 1.5rem;
    margin-bottom: 1rem
}

    .zuODa .vTz4P {
    display: inline-block;
    vertical-align: middle
}

    @media (max-width: 47.999em) {

    .zuODa .vTz4P {
        padding-right: 0.75rem
    }
}

    @media (min-width: 48em) {

    .zuODa .vTz4P {
        padding-right: 1rem
    }
}

    .zuODa .fDY-- {
    line-height: 2.25rem;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity))
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- {
        margin-top: 0.375rem;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 1rem
    }
}

    @media (min-width: 48em) {

    .zuODa .fDY-- {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 1.25rem;
        margin-right: 1.25rem
    }
}

    .zuODa .fDY-- .fDKjm, .zuODa .fDY-- .Hv-gZ {
    display: inline-block;
    padding-right: 0.75rem;
    vertical-align: middle
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .fDKjm, .zuODa .fDY-- .Hv-gZ {
        display: block
    }
}

    .zuODa .fDY-- .fDKjm {
    padding-right: 0px
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .fDKjm {
        padding-left: 0px
    }
}

    @media (min-width: 48em) and (max-width: 79.9375em) {

    .zuODa .fDY-- .fDKjm {
        position: relative;
        top: -0.125rem
    }
}

    .zuODa .fDY-- .iIBvh:hover {
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity))
}

    .zuODa .fDY-- .iIBvh .Z0ghf {
    display: table-cell
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .Z0ghf {
        height: 2rem;
        width: 2rem
    }
}

    .zuODa .fDY-- .iIBvh .zsJxQ {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 700;
    text-align: center;
    vertical-align: middle
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .zsJxQ {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        padding-left: 0.5rem;
        font-size: 0.75rem;
        line-height: 1rem
    }
}

    @media (min-width: 48em) and (max-width: 79.9375em) {

    .zuODa .fDY-- .iIBvh .zsJxQ {
        font-size: 0.875rem;
        line-height: 1.25rem
    }
}

    @media (min-width: 80em) {

    .zuODa .fDY-- .iIBvh .zsJxQ {
        font-size: 1.125rem;
        line-height: 1.5rem
    }
}

    @media (--viewport-md) {

    .zuODa .fDY-- .iIBvh .zsJxQ ._0Cm9d {
                        vertical-align: -2px
                }
                    }

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 {
    padding-left: 0.25rem;
    vertical-align: top
}

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj {
    float: left;
    font-size: 1.125rem;
    line-height: 1.5rem
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj {
        font-size: 0.75rem
    }
}

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj .KdbN1 {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0em;
    --tw-text-opacity: 1;
    color: rgba(231, 19, 36, var(--tw-text-opacity))
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj .KdbN1 {
        font-size: 1.25rem;
        line-height: 1.4
    }
}

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj .KdbN1 .iKD5D {
    display: inline-block
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj .KdbN1 .iKD5D {
        width: auto
    }
}

    @media (min-width: 48em) {

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj .KdbN1 .iKD5D {
        width: auto
    }
}

    @media (min-width: 80em) {

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj .KdbN1 .iKD5D {
        width: 26px
    }
}

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .-KHzj .KdbN1 .iKD5D.FvCYC {
    width: auto
}

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .cUAZs {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
    margin-left: 0px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity))
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .zsJxQ ._8jY57 ._8Wxwf .cUAZs {
        font-size: 0.75rem
    }
}

    .zuODa .fDY-- .iIBvh .zsJxQ .XebK6 {
    display: inline-block;
    padding-right: 0.25rem
}

    .zuODa .fDY-- .iIBvh .zsJxQ .-rnqQ {
    display: inline-block
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .zsJxQ .-rnqQ {
        padding-right: 0px
    }

    .zuODa .fDY-- .iIBvh .zsJxQ .tKInt {
        vertical-align: baseline
    }
}

    .zuODa .fDY-- .iIBvh .zsJxQ .cgcxE {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 600;
    display: inline-block;
    padding-right: 0.25rem;
    font-size: 1.25rem;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0em;
    --tw-text-opacity: 1;
    color: rgba(32, 32, 32, var(--tw-text-opacity))
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .zsJxQ .cgcxE {
        font-size: 1rem;
        line-height: 1.25rem
    }
}

    .zuODa .fDY-- .iIBvh .zsJxQ .kokkJ {
    display: inline-block;
    padding-right: 0.25rem;
    --tw-text-opacity: 1;
    color: rgba(231, 19, 36, var(--tw-text-opacity))
}

    .zuODa .fDY-- .iIBvh .zsJxQ .fKoaM {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
    display: inline-block;
    padding-right: 0.375rem;
    font-size: 0.75rem;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0em;
    --tw-text-opacity: 1;
    color: rgba(102, 102, 102, var(--tw-text-opacity))
}

    @media (max-width: 47.999em) {

    .zuODa .fDY-- .iIBvh .zsJxQ .fKoaM {
        padding-top: 0.25rem
    }
}

    @media (min-width: 80em) {

    .zuODa .fDY-- .iIBvh .zsJxQ .fKoaM {
        font-size: 0.75rem
    }
}

    .zuODa .xKZf9 .vTz4P, .zuODa .xKZf9 ._0Cm9d {
    cursor: pointer
}

    .zuODa ._4cByX {
    margin-bottom: 1rem
}

    .zuODa .vs7Xu {
    display: inline-block;
    cursor: default;
    vertical-align: baseline
}

    @media (max-width: 47.999em) {

    .zuODa .vs7Xu {
        display: inline-block;
        padding-left: 0px;
        vertical-align: text-bottom
    }
}

    @media (--viewport-md) {

    .zuODa .vs7Xu {
            vertical-align: -2px
    }
        }

    @media (max-width: 47.999em) {

    .zuODa .vs7Xu .eVWKu ._6uBEz {
        right: -30px
    }
}

    .zuODa .eVWKu {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
    position: relative;
    z-index: auto;
    font-size: 0.75rem;
    line-height: 1rem;
    --tw-text-opacity: 1;
    color: rgba(102, 102, 102, var(--tw-text-opacity))
}

    .zuODa .eVWKu ._6uBEz {
    top: 29px;
    z-index: 10;
    border-width: 0px;
    background-color: transparent;
    padding-bottom: 1.25rem
}

    .zuODa .eVWKu ._6uBEz::after {
    --tw-content: 'none';
    content: var(--tw-content)
}

    @media (max-width: 47.999em) {

    .zuODa .eVWKu ._6uBEz {
        top: 27px
    }
}

    .zuODa .eVWKu ._6uBEz::after {
                content: none;
            }

    .zuODa .eVWKu ._9Xuku {
    top: 27px;
    z-index: 10
}

    .zuODa .eVWKu .-DpDz {
    position: relative;
    width: 418px;
    border-width: 0px;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-shadow: 0 2px 4px 0 rgba(32, 32, 32, 0.25);
    --tw-shadow-colored: 0 2px 4px 0 var(--tw-shadow-color);
    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow)
}

    .zuODa .eVWKu .-DpDz::after {
    --tw-content: 'none';
    content: var(--tw-content)
}

    @media (max-width: 47.999em) {

    .zuODa .eVWKu .-DpDz {
        width: 202px
    }
}

    .zuODa .eVWKu .-DpDz::after {
                content: none;
            }

    .zuODa .eVWKu .IjKRo {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
    cursor: pointer;
    white-space: nowrap;
    font-size: 0.75rem;
    line-height: 1rem;
    --tw-text-opacity: 1;
    color: rgba(102, 102, 102, var(--tw-text-opacity));
    text-decoration-line: underline
}

    .zuODa .eVWKu .AU5nw {
    position: absolute;
    right: 0px;
    height: 2rem;
    width: 2rem;
    cursor: pointer
}

    @media (max-width: 47.999em) {

    .zuODa .eVWKu .AU5nw.WqGNu {
        display: none
    }
}

    @media (min-width: 0em) {

    .zuODa .eVWKu .Ia3Cd {
        padding-top: 1.5rem;
        padding-bottom: 2.5rem
    }
}

    @media (max-width: 47.999em) {

    .zuODa .eVWKu .Ia3Cd {
        padding-left: 1rem;
        padding-right: 1rem
    }
}

    @media (min-width: 48em) {

    .zuODa .eVWKu .Ia3Cd {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}

    .zuODa .eVWKu .qH3E4 {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
    position: relative;
    max-height: 120px;
    overflow: hidden;
    padding-right: 0px;
    text-align: left;
    font-size: 0.75rem;
    line-height: 1rem
}

    .zuODa .eVWKu .qH3E4::before {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 2.5rem;
    content: var(--tw-content);
    width: 100%
}

    @media (max-width: 47.999em) {

    .zuODa .eVWKu .qH3E4 {
        max-height: 86px
    }
}

    .zuODa .eVWKu .qH3E4::before {
                content: '';
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
            }

    .zuODa .eVWKu .hjOd1 {
    float: right;
    margin-top: 7px;
    cursor: pointer;
    font-size: 11px;
    text-decoration-line: underline
}

    .zuODa .eVWKu .UVjLG {
    padding-bottom: 1rem
}

    .zuODa .eVWKu .UVjLG .qH3E4 {
    max-height: none
}

    .zuODa .eVWKu .UVjLG .qH3E4::before {
    height: 0px;
    content: var(--tw-content);
    background-color: transparent;
                    content: '';
                    background-image: none
}

    .zuODa .eVWKu .UVjLG .hjOd1 {
    display: none
}

    .zuODa .gCdxL {
    position: absolute;
    left: 17px;
    height: 13px;
    width: 22px;
    overflow: hidden
}

    .zuODa .gCdxL::after {
    position: absolute;
    top: 7px;
    left: 0.25rem;
    height: 0.75rem;
    width: 0.75rem;
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    --tw-content: '';
    content: var(--tw-content)
}

    @media (max-width: 47.999em) {

    .zuODa .gCdxL {
        left: 1.25rem;
        height: 11px
    }
}

    .zuODa .gCdxL::after {
            box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
        }

    .zuODa .gCdxL.WqGNu {
    display: none
}

    .zuODa .SMlJp {
    font-family: "Open Sans", OpenSans, Helvetica, arial, sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0em;
    --tw-text-opacity: 1;
    color: rgba(102, 102, 102, var(--tw-text-opacity))
}
