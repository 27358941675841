.Uu1gO.MJ-lu {
        transition: left 2s ease-in-out;
    }

        .Uu1gO.MJ-lu.ZzcPD {
            transition: left 0.25s, background 3s ease-in-out;
        }
    .Uu1gO .JryqS {
        transition: all .1s ease-out;
    }

.nMc2b {
    border: 3px solid rgba(255, 255, 255, 0.7);
    border-top-color: rgba(0, 0, 0, .7);
    animation: o4D5d 0.5s infinite linear;
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    margin-top: -1rem;
    margin-left: -1rem;
    box-sizing: border-box;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 9999px;
}

@keyframes o4D5d {
    100% {
        transform: rotate(360deg);
    }
}
