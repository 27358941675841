.rI8pK.YKK2D .KBeSb .ugg-V {
    font-size: 1.25rem;
    font-weight: 400;
    --tw-content: '';
    content: var(--tw-content)
}
    .rI8pK.tdASI .KBeSb .ugg-V use {
    stroke: #999;
    stroke-width: 20px
}
